import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserResponseDto } from '../../services/user.service';

interface ITableProps {
  list: UserResponseDto[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

const UserTable = ({ list, onEdit, onDelete }: ITableProps) => {
  const { t } = useTranslation();

  return (
    <table className='dataTable px-3 questionTable'>
      <thead>
        <tr className='trow-header fw-bold'>
          <td className='secondaryTitle-bold py-3' style={{ width: '60%' }}>
            {t('userList.table.email')}
          </td>
          <td className='td-action py-3' style={{ width: '10%' }}>
            {t('userList.table.actions')}
          </td>
        </tr>
      </thead>
      <tbody>
        {list.map((user: UserResponseDto, j) => {
          return (
            <tr key={`tr-${user.id}`}>
              <td className='secondaryTitle-bold py-3'>{user.email}</td>
              <td className='py-3 d-flex justify-content-between'>
                <span className='editLink' onClick={() => onEdit(j)}>
                  <i className='bi bi-pencil-fill'></i>{' '}
                  {t('questionList.table.editBtn')}
                </span>
                <span className='deleteLink' onClick={() => onDelete(j)}>
                  <i className='bi bi-trash-fill'></i>{' '}
                  {t('questionList.table.deleteBtn')}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UserTable;
