import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AbstractModal,
  FormTextInput,
  InputLabel,
  PrimaryButton,
  TextInput,
} from '../../components';
import userService, {
  UserCreateDto,
  UserResponseDto,
  UserUpdateDto,
} from '../../services/user.service';
import UserTable from './UserTable';
import Alert from 'react-s-alert';

const nullUser = {
  email: '',
  password: '',
  id: '',
};

const UserList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [list, setList] = useState<UserResponseDto[]>([]);
  const [editUserOpen, setEditUserOpen] = useState<boolean>(false);
  const [createUserOpen, setCreateUserOpen] = useState<boolean>(false);
  const [createUserPayload, setCreateUserPayload] =
    useState<UserCreateDto>(nullUser);
  const [updateUserPayload, setUpdateUserPayload] =
    useState<UserUpdateDto>(nullUser);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    userService
      .list()
      .then(r => {
        setList(r.data);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  const onEdit = (index: number) => {
    setEditUserOpen(true);
    const user = list[index];
    setUpdateUserPayload({
      ...user,
      password: '',
    });
  };

  const onDelete = (index: number) => {
    const user = list[index];
    userService.delete(user.id).then(r=>{
      Alert.success("Sucessfully deleted user")
      getUsers()
    }).catch(e => {
      Alert.error("Not able to delete this user")
    })
  };

  const createUser = () => {
    if (
      !validateEmail(createUserPayload.email) ||
      !(createUserPayload.password.length > 6)
    ) {
      Alert.warning(t('userList.warnings.createUserData'));
      return;
    }
    userService.create(createUserPayload).then(user => {
      console.log(`user created: ${user}`);
      Alert.success('User was created!');
      setCreateUserPayload(nullUser);
      setCreateUserOpen(false);
      getUsers();
    });
  };

  const updateUser = () => {
    if (
      !validateEmail(updateUserPayload.email) ||
      !(updateUserPayload.password.length > 6)
    ) {
      Alert.warning(t('userList.warnings.createUserData'));
      return;
    }
    userService.update(updateUserPayload).then(user => {
      Alert.success('User was updated!');
      setUpdateUserPayload(nullUser);
      setEditUserOpen(false);
      getUsers();
    });
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <div className='private_body pb-2'>
      <div className='private_container'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='fs-5 fw-bold'>
            {t('userList.title')}{' '}
            <span className='secondaryTitle-bold'>({list.length})</span>
          </div>

          <div className='d-flex align-items-center'>
            <PrimaryButton
              title={t('userList.primaryAction')}
              icon='bi bi-plus-circle'
              onClick={() => {
                setCreateUserOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <UserTable list={list} onEdit={onEdit} onDelete={onDelete} />

      {createUserOpen && (
        <AbstractModal
          label={t('userList.createModal.title')}
          leftBtn={{
            label: t('userList.createModal.cancel'),
            onClick: () => {
              setCreateUserPayload(nullUser);
              setCreateUserOpen(false);
            },
          }}
          rightBtn={{
            label: t('userList.createModal.create'),
            onClick: createUser,
          }}
        >
          <form>
            <InputLabel label='Email' />
            <FormTextInput
              placeholder={t('userList.createModal.emailPlaceholder')}
              value={createUserPayload?.email || ''}
              handleChange={(e: any) => {
                setCreateUserPayload({
                  ...createUserPayload,
                  email: e.target.value,
                });
              }}
              required
            />
            <br />
            <InputLabel label='Password' />
            <FormTextInput
              inputType='password'
              placeholder={t('userList.createModal.passwordPlaceholder')}
              value={createUserPayload?.password || ''}
              handleChange={(e: any) => {
                setCreateUserPayload({
                  ...createUserPayload,
                  password: e.target.value,
                });
              }}
              required
            />
          </form>
        </AbstractModal>
      )}
      {editUserOpen && (
        <AbstractModal
          label={t('userList.updateModal.title')}
          leftBtn={{
            label: t('userList.updateModal.cancel'),
            onClick: () => {
              setUpdateUserPayload(nullUser);
              setEditUserOpen(false);
            },
          }}
          rightBtn={{
            label: t('userList.updateModal.create'),
            onClick: updateUser,
          }}
        >
          <form>
            <InputLabel label='Email' />
            <FormTextInput
              placeholder={t('userList.updateModal.emailPlaceholder')}
              value={updateUserPayload?.email || ''}
              handleChange={(e: any) => {
                setUpdateUserPayload({
                  ...updateUserPayload,
                  email: e.target.value,
                });
              }}
              required
            />
            <br />
            <InputLabel label='Password' />
            <FormTextInput
              inputType='password'
              placeholder={t('userList.updateModal.passwordPlaceholder')}
              value={updateUserPayload?.password || ''}
              handleChange={(e: any) => {
                setUpdateUserPayload({
                  ...updateUserPayload,
                  password: e.target.value,
                });
              }}
              required
            />
          </form>
        </AbstractModal>
      )}
    </div>
  );
};

export default UserList;
