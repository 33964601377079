import { AxiosResponse } from 'axios';
import { BaseApiService } from './index';

export interface UserResponseDto {
  email: string;
  id: string;
}

export interface UserCreateDto {
  email: string;
  password: string;
}

export interface UserUpdateDto {
  id: string;
  email: string;
  password: string;
}

class UserService {
  async list(): Promise<AxiosResponse<UserResponseDto[]>> {
    return BaseApiService.get(`/api/user`);
  }

  async create(
    payload: UserCreateDto,
  ): Promise<AxiosResponse<UserResponseDto>> {
    return BaseApiService.post<UserResponseDto>('/api/user/', payload);
  }

  async update(
    payload: UserUpdateDto,
  ): Promise<AxiosResponse<UserResponseDto>> {
    const uri = `/api/user/${payload.id}`;
    return BaseApiService.patch<UserResponseDto>(uri, payload);
  }

  async delete(userId: string) {
    const uri = `/api/user/${userId}`;
    return BaseApiService.delete(uri);
  }
}

export default new UserService();
